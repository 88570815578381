






import { Component, Vue, Provide } from 'vue-property-decorator';
import CourseList from '../../shared/components/CourseList.component.vue';
import ReviewQuestionsProvider from '../../providers/reviewQuestions.provider';
import { APPIDS, REVIEW_QUESTIONS_ROUTES_NAMES } from '../../enums';

@Component({
  components: { CourseList },
})
export default class ReviewQuestionsCoursesList extends Vue {
  @Provide() Provider = ReviewQuestionsProvider;

  appId = APPIDS.REVIEW_QUESTIONS;

  name = 'reviewQuestionsCoursesList';

  goReviewQuestionsList(courseData) {
    this.$router.push({
      name: REVIEW_QUESTIONS_ROUTES_NAMES.LIST,
      params: {
        courseId: courseData.courseId.toString(),
      },
    });
  }
}
